import React from "../../../_snowpack/pkg/react.js";
import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import "./Loader.css";
export default function Loader({colour, style, size}) {
  return /* @__PURE__ */ React.createElement("div", {
    className: "lds-heart",
    style: {width: size, height: size, ...style}
  }, /* @__PURE__ */ React.createElement("div", {
    style: {
      background: colour,
      width: size * 0.4,
      height: size * 0.4,
      left: size * 0.3,
      top: size * 0.3
    }
  }, /* @__PURE__ */ React.createElement("div", {
    className: "div-before",
    style: {
      background: colour,
      width: size * 0.4,
      height: size * 0.4,
      left: -size * 0.3
    }
  }), /* @__PURE__ */ React.createElement("div", {
    className: "div-after",
    style: {
      background: colour,
      width: size * 0.4,
      height: size * 0.4,
      top: -size * 0.3
    }
  })));
}
Loader.propTypes = {
  colour: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number
};
Loader.defaultProps = {
  colour: "#ff6961",
  style: {},
  size: 128
};
