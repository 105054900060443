import React, {useState, useEffect} from "../_snowpack/pkg/react.js";
import Dropzone from "../_snowpack/pkg/react-dropzone.js";
import Loader from "./components/Loader/index.js";
import {createFFmpeg, fetchFile} from "../_snowpack/pkg/@ffmpeg/ffmpeg.js";
const ffmpeg = createFFmpeg({log: true});
function App() {
  const [ready, setReady] = useState(false);
  const [video, setVideo] = useState();
  const [gif, setGif] = useState();
  const load = async () => {
    await ffmpeg.load();
    setReady(true);
  };
  useEffect(() => {
    load();
  }, []);
  const convertToGif = async () => {
    ffmpeg.FS("writeFile", "input.mp4", await fetchFile(video));
    await ffmpeg.run("-i", "input.mp4", "-f", "gif", "out.gif");
    const data = ffmpeg.FS("readFile", "out.gif");
    const url = URL.createObjectURL(new Blob([data.buffer], {type: "image/gif"}));
    setGif(url);
  };
  return ready ? /* @__PURE__ */ React.createElement("div", {
    className: "font-sans text-xl"
  }, /* @__PURE__ */ React.createElement(Dropzone, {
    onDrop: (files) => {
      setVideo(files[0]);
      setGif(null);
    },
    accept: ["video/*"],
    maxFiles: 1
  }, ({getRootProps, getInputProps}) => /* @__PURE__ */ React.createElement("section", {
    className: "grid justify-center mt-10"
  }, /* @__PURE__ */ React.createElement("div", {
    ...getRootProps({className: "dropzone"})
  }, /* @__PURE__ */ React.createElement("input", {
    ...getInputProps()
  }), /* @__PURE__ */ React.createElement("p", null, "Upload your video here (:")))), video && /* @__PURE__ */ React.createElement("div", {
    className: "grid justify-center mt-10"
  }, /* @__PURE__ */ React.createElement("video", {
    controls: true,
    width: 250,
    src: URL.createObjectURL(video)
  }), /* @__PURE__ */ React.createElement("button", {
    onClick: convertToGif,
    className: "m-10"
  }, "Convert"), gif && /* @__PURE__ */ React.createElement("img", {
    src: gif,
    width: 250
  }))) : /* @__PURE__ */ React.createElement("div", {
    className: "fixed top-1/4 left-1/2",
    style: {transform: "translate(-50%, -50%)"}
  }, /* @__PURE__ */ React.createElement(Loader, null));
}
export default App;
